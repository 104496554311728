import { AccountRole } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type RoleInfo = InfoType<AccountRole> & {
  id: AccountRole;
};

export const teamRolesInfo: RoleInfo[] = [
  {
    id: 'ridebooker',
    name: 'shared.teamRoles.ridebooker.name',
    description: 'shared.teamRoles.ridebooker.description'
  },
  {
    id: 'dispatcher',
    name: 'shared.teamRoles.dispatcher.name',
    description: 'shared.teamRoles.dispatcher.description'
  }
];

export const accountRolesInfo: RoleInfo[] = [
  {
    id: 'owner',
    name: 'shared.accountRoles.owner.name',
    description: 'shared.accountRoles.owner.description'
  },
  {
    id: 'admin',
    name: 'shared.accountRoles.admin.name',
    description: 'shared.accountRoles.admin.description'
  },
  {
    id: 'operator',
    name: 'shared.accountRoles.operator.name',
    description: 'shared.accountRoles.operator.description'
  },
  {
    id: 'ridebooker',
    name: 'shared.accountRoles.ridebooker.name',
    description: 'shared.accountRoles.ridebooker.description'
  },
  {
    id: 'dispatcher',
    name: 'shared.accountRoles.dispatcher.name',
    description: 'shared.accountRoles.dispatcher.description'
  }
];
