import { InsuranceType } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type InsuranceTypeInfo = InfoType<InsuranceType> & {
  id: InsuranceType;
};

export const insuranceTypes: InsuranceTypeInfo[] = [
  {
    id: 'public_insurance',
    name: 'shared.insuranceTypes.public_insurance'
  },
  {
    id: 'private_insurance',
    name: 'shared.insuranceTypes.private_insurance'
  },
  {
    id: 'german_accident_insurance',
    name: 'shared.insuranceTypes.german_accident_insurance'
  },
  {
    id: 'no_or_unknown_insurance',
    name: 'shared.insuranceTypes.no_or_unknown_insurance'
  }
];
