import { InfoType } from '@shared/models';

export type DayOfTheWeekInfo = InfoType<number> & {
  id: number;
};

export const shortDaysOfTheWeek: DayOfTheWeekInfo[] = [
  {
    id: 1,
    name: 'global.mondayShort'
  },
  {
    id: 2,
    name: 'global.tuesdayShort'
  },
  {
    id: 3,
    name: 'global.wednesdayShort'
  },
  {
    id: 4,
    name: 'global.thursdayShort'
  },
  {
    id: 5,
    name: 'global.fridayShort'
  },
  {
    id: 6,
    name: 'global.saturdayShort'
  },
  {
    id: 0,
    name: 'global.sundayShort'
  }
];
