import { InfoType } from '@shared/models';

export type RideTypeInfo = InfoType<'ambulance' | 'taxi'> & {
  id: 'ambulance' | 'taxi';
};

export const rideTypes: RideTypeInfo[] = [
  {
    id: 'ambulance',
    name: 'shared.rideTypes.ambulance'
  },
  {
    id: 'taxi',
    name: 'shared.rideTypes.taxi'
  }
];
