/* eslint-disable @typescript-eslint/no-explicit-any */
import { TranslateService } from '@ngx-translate/core';
import { ApplicationFacade } from '@shared/data';
import { InjectorProvider, NAVIGATOR } from '@shared/providers';
import { LocalStorageService } from '@shared/services';
import { NzI18nService } from 'ng-zorro-antd/i18n';

/**
 * Get the user's preferred language
 * @returns String with short country code
 */
export const getPreferredLanguage = (): string => {
  const appFacade = InjectorProvider.injector.get(ApplicationFacade);
  const localStorage = InjectorProvider.injector.get(LocalStorageService);

  return (
    appFacade.language() ||
    localStorage.get<string>('lastLanguage') ||
    getSystemLanguage() ||
    'en'
  );
};

/**
 * Set a selected language to the localStorage
 * @param language String with short country code
 */
export const setLanguageInStorage = (language: string): void => {
  InjectorProvider.injector
    .get(LocalStorageService)
    .set('lastLanguage', language);
};

/**
 * Get the system's language
 * @returns String with short country code or 'en' as the default
 */
export const getSystemLanguage = (): string => {
  const navigator = InjectorProvider.injector.get(NAVIGATOR);
  const result = navigator.language?.split('-')[0];

  if (result && ['fr', 'de', 'en', 'nl'].includes(result)) {
    return result;
  }

  return 'en';
};

/**
 * Initializes the language settings for the application.
 * @param language - The language code to be set.
 */
export const initLanguage = (language: string): void => {
  const appFacade = InjectorProvider.injector.get(ApplicationFacade);
  const translateService = InjectorProvider.injector.get(TranslateService);
  const nzI18n = InjectorProvider.injector.get(NzI18nService);

  if (appFacade.userProfile()) {
    const profile = appFacade.userProfile();
    appFacade.setUserProfile({
      ...profile,
      info: { ...profile.info, language }
    });
  }

  setLanguageInStorage(language);
  translateService.use(language);

  // Set preferred language to Ant Design components
  switch (language) {
    case 'de':
      import('date-fns/locale/de').then((module: any) =>
        nzI18n.setDateLocale(module?.default?.de)
      );
      import('ng-zorro-antd/i18n').then(
        (module: typeof import('ng-zorro-antd/i18n')) =>
          nzI18n.setLocale(module.de_DE)
      );
      break;
    case 'fr':
      import('date-fns/locale/fr').then((module: any) =>
        nzI18n.setDateLocale(module?.default?.fr)
      );
      import('ng-zorro-antd/i18n').then(
        (module: typeof import('ng-zorro-antd/i18n')) =>
          nzI18n.setLocale({
            ...module.fr_FR,
            QRCode: {
              expired: 'Expiré',
              refresh: 'Actualiser',
              scanned: 'Scanné'
            }
          })
      );
      break;
    case 'nl':
      import('date-fns/locale/nl').then((module: any) =>
        nzI18n.setDateLocale(module?.default?.nl)
      );
      import('ng-zorro-antd/i18n').then(
        (module: typeof import('ng-zorro-antd/i18n')) =>
          nzI18n.setLocale(module.nl_NL)
      );
      break;
    default:
      import('date-fns/locale/en-GB').then((module: any) => {
        nzI18n.setDateLocale(module?.default?.enGB);
      });
      import('ng-zorro-antd/i18n').then(
        (module: typeof import('ng-zorro-antd/i18n')) =>
          nzI18n.setLocale(module.en_GB)
      );
      break;
  }
};

/**
 * Dynamically imports the Angular locale data for the specified language.
 * Source: https://github.com/angular/angular-cli/issues/26904#issuecomment-1913572110
 *
 * @param language - The language code for which to import the locale data (e.g., 'en', 'fr', 'de', 'nl').
 * @returns A promise that resolves to the imported locale data.
 */

export const importAngularLocale = async (language): Promise<any> => {
  switch (language) {
    case 'en':
      return await import(`@angular/common/locales/en`);
    case 'fr':
      return await import(`@angular/common/locales/fr`);
    case 'de':
      return await import(`@angular/common/locales/de`);
    case 'nl':
      return await import(`@angular/common/locales/nl`);
  }
};
