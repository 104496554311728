import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Message, Payload } from '@shared/models';
import { EnvironmentService } from '@shared/services';
import { Observable } from 'rxjs';

export abstract class FluxRequestGateway {
  private readonly http = inject(HttpClient);
  private readonly environment = inject(EnvironmentService);

  protected abstract path: string; // Override with actual API endpoint

  send<T>(
    type: string,
    payload: Message | Payload,
    options?: object
  ): Observable<T> {
    const message = this.asMessage(payload, type);

    return this.http.post<T>(
      this.environment.apiHost + this.path,
      message,
      options
    );
  }

  private asMessage(payload: Payload | Message, type: string): Message {
    const fluxMessageClass = 'io.fluxcapacitor.javaclient.common.Message';

    // check if a payload is already a message
    if (payload['@class'] === fluxMessageClass) {
      const payloadMessage = payload as Message;

      return {
        '@class': payloadMessage['@class'],
        metadata: payloadMessage.metadata ?? {
          localTime: new Date().toISOString()
        },
        payload: {
          ...payloadMessage.payload,
          '@class': type
        }
      };
    }

    return {
      '@class': fluxMessageClass,
      metadata: {
        localTime: new Date().toISOString()
      },
      payload: {
        ...payload,
        '@class': type
      }
    };
  }
}
