import { Injectable } from '@angular/core';
import { environment } from 'src/environments';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  get production(): boolean {
    return environment.production;
  }

  get testing(): boolean {
    return environment.testing;
  }

  get apiHost(): string {
    return environment.apiHost;
  }

  get googleMapsLibraries(): string[] {
    return environment.googleMapsLibraries;
  }
}
