import { EntityType } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type EntityTypeInfo = InfoType<EntityType> & {
  id: EntityType;
};

export const entityTypes: EntityTypeInfo[] = [
  {
    id: 'ride',
    name: 'shared.entityTypes.ride'
  },
  {
    id: 'rideseries',
    name: 'shared.entityTypes.rideseries'
  }
];
