import { Mobility } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type MobilityInfo = InfoType<Mobility> & {
  id: Mobility;
};

export const mobilityTypes: MobilityInfo[] = [
  {
    id: 'wheelchair',
    name: 'shared.mobilityTypes.wheelchair'
  },
  {
    id: 'big_or_electric_wheelchair',
    name: 'shared.mobilityTypes.big_or_electric_wheelchair'
  },
  {
    id: 'carry_chair',
    name: 'shared.mobilityTypes.carry_chair'
  },
  {
    id: 'stretcher',
    name: 'shared.mobilityTypes.stretcher'
  }
];
