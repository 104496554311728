interface NutsCodeInfo {
  name: string;
  code: string;
}

interface NutsCodeDistrict {
  districts: NutsCodeInfo[];
}

type NutsCodeSubState = NutsCodeInfo & NutsCodeDistrict;

type NutsCode = NutsCodeInfo & {
  substates: NutsCodeSubState[];
};

/**
 * Imports the list of nutsCodes dynamically (i.e., when the function is called)
 * @returns A promise that resolves to an array of nutsCodes objects.
 */

export const nutsCodes = async (): Promise<NutsCode[]> =>
  await import('@moxi.gmbh/moxi-typescriptmodels/nuts-districts.json').then(
    nuts => nuts.default as NutsCode[]
  );
